import Swal from 'sweetalert2';
import number from '../mixins/number';
import { mapGetters, mapActions } from 'vuex';

export default {
	data() {
		return {
			current_window: null
		}
	},
	mixins: [number],
	methods: {
		...mapActions('game', [
			'getGames',
			'launchHonorlink',
			'launchMamahot',
			'exitMamahot',
		]),
		openPopup(id) {
			let popupBg = document.getElementById(id)
			try {
				popupBg.classList.add("bg-active");
			} catch (e) {
				console.log(id)
			}
			popupBg.addEventListener('mousedown', function (e) {
				e = window.event || e;
				if (this === e.target) {
					popupBg.classList.remove('bg-active')
				}
			});
		},
		closePopup(id) {
			let popupBg = document.getElementById(id)
			try {
				popupBg.classList.remove("bg-active");
			} catch (e) {
				console.log(id)
			}
		},
		async convertPoints() {
			const confirmed = await Swal.fire({
				title: '포인트머니를 전환 하시겠습니까?',
				icon: 'question',
				showCancelButton: true,
				cancelButtonText: this.$t('cancel'),
				confirmButtonText: this.$t('ok'),
				timer: 0,
			}).then((result) => {
				return result.isConfirmed;
			});
			if (confirmed) {
				const res = await await this.$store.dispatch(
					'auth/convertPoints'
				);
				Swal.fire({
					title: this.$t(res.data.message),
					icon: res.data.status,
					timer: res.success ? 2000 : null,
					confirmButtonText: this.$t('ok'),
				});
				if (res.success) {
					this.$store.dispatch('auth/getProfile');
				}
			}
		},
		async launchHonorlinkGame(game) {
			const res = await this.launchHonorlink({
				game_id: game['id'],
				vendor: game['vendor'],
				lang: "ko",
			});
			if (res.status == 200) {
				this.openGameWindow(res.data.data.url, game['id'], game['vendor'], game['category'] ? game['category'] : this.$route.params.type)
			} else if (res.status == 401) {
				this.$router.replace({ path: '/' }).catch(() => { });
			} else {
				Swal.fire({
					icon: 'error',
					title: this.$t(res.data.message ? res.data.message.toLowerCase() : 'An error occured'),
					confirmButtonText: this.$t('ok'),
					showConfirmButton: true,
					timer: 0
				});
			}
		},
		async openGameWindow($url, window_id, vendor, category) {
			var vm = this
			var queryParams = {
				url: $url,
				vendor: vendor,
			};

			var queryString = Object.keys(queryParams).map((key) => key + '=' + encodeURIComponent(queryParams[key])).join('&');
			var window_name = this.gameLaunchMode === 'single' ? 'game' : `game-${window_id}`;
			// var windowOpen = window.open($url,`_blank`);
			var windowOpen = window.open(`/game-launch/${category}?${queryString}`, window_name,
				'width=' + screen.availWidth + ',height=' + screen.availHeight + ',fullscreen=yes, toolbar=no, location=no, directories=no, status=no, menubar=no,scrollbars=no,resizable=no'
			);

			var polling = null;
			polling = setInterval(async () => {
				try {
					if (windowOpen.closed) {
						clearInterval(polling)
						await this.exitMamahot();
						await this.$store.dispatch('auth/getProfile');
					}
				}
				catch (e) { }
			}, 1000)

			window.addEventListener('beforeunload', async function () {
				try {
					vm.$store.commit('closeAllWindow');
					await this.exitMamahot();
					windowOpen.close();
				} catch (e) {
					console.error(e)
				}
			});
		}
	},

	computed: {
		...mapGetters('auth', ['authenticated', 'profile']),
		gameLaunchMode() {
			return process.env.VUE_APP_GAME_LAUNCH_MODE;
		},
		oneGameAtATime() {
			return this.$store.state.popups.length > 0
				&& process.env.VUE_APP_GAME_LAUNCH_MODE == 'single'
		},
		myBalance() {
			var balance = null;
			if (
				process.env.VUE_APP_GAME_LAUNCH_MODE == 'single' &&
				this.$store.state.popups.length > 0
			) {
				balance = '0';
				// if (this.$store.state.gameType == 'slot') {
				// 	balance = '0';
				// } else if (this.$store.state.gameType == 'casino') {
				// 	balance = this.numberString(this.profile.balance);
				// }
			} else if (
				process.env.VUE_APP_GAME_LAUNCH_MODE == 'multiple' &&
				this.$store.state.popups.length > 0
			) {
				balance = '0';
				// if (this.$store.state.gameType == 'slot') {
				// 	balance = '0';
				// } else if (this.$store.state.gameType == 'casino') {
				// 	balance = this.numberString(this.profile.balance);
				// }
			} else {
				balance = this.numberString(this.profile.balance);
			}
			return balance;
		},

	},
	watch: {
		authenticated(n) {
			try {
				this.$store.commit('closeAllWindow')
				if (this.current_window) {
					this.current_window.close();
				}
			} catch (e) {
				console.log(e)
			}
		},
	},
}