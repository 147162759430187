import $api from "../api";
import router from "../../router/index";
import $cookies from "vue-cookies";
import Swal from "sweetalert2";
import Pusher from "pusher-js";
const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
	cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
});

export const state = {
	token: $cookies.get("token"),
	type: 1,
	id: 1,
	profile: {
		type: null,
		loading: true,
		routeTo: null,
		username: null,
		role: null,
		status: null,
		last_login_at: null,
		last_login_ip: null,
		balance: 0,
		points: 0,
		transfer_in_game: false,
		withdraw: {
			pin: false,
			password: false,
		},
		ticket: {
			unread: 0,
		},
		coupon: {
			unclaimed: 0,
		},
		menu: {
			table: "honorlink",
			slots: "honorlink"
		}
	},
};

export const actions = {
	loginUser({ commit }, pl) {
		return new Promise(function (resolve) {
			$api
				.post("auth/player/login", pl)
				.then(function (res) {
					if (res.status == 200) {
						commit("setToken", res.data.data);
						resolve({
							success: true,
							data: res.data
						});
					}
				})
				.catch(function (err) {
					resolve({
						success: false,
						data: err.response.data
					});
				});
		});
	},
	logoutUser({ commit, getters }) {
		return new Promise(function (resolve) {
			$api
				.get("auth/logout", {
					headers: { Authorization: getters.bearer_token },
				})
				.then(function () {
					commit("destroyAuth");
					resolve(true);
				})
				.catch(function () {
					commit("destroyAuth");
					resolve(true);
				});
		});
	},
	getProfile({ commit, getters, dispatch }) {
		return new Promise(function (resolve) {
			$api
				.get("auth/profile", {
					headers: { Authorization: getters.bearer_token },
				})
				.then(function (res) {
					if (res.status === 200) {
						commit("setProfile", res.data.data);
					}
					resolve(true);
				})
				.catch(function (err) {
					if (err.response && err.response.status == 401) {
						commit("destroyAuth");
					}
					resolve(false);
				});
		});
	},
	changePass({ rootGetters, dispatch }, pl) {
		return new Promise(function (resolve) {
			$api
				.post("/player/change-password", pl, {
					headers: {
						Authorization: rootGetters["auth/bearer_token"],
					},
				})
				.then(function (res) {
					resolve(res.data);

				})
				.catch(function (err) {
					if (err.response.status == 401) {
						dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
					}
					if (err.response.status == 422) {
						console.log(err);
						resolve(err.response);
					}
				});
		});
	},
	registerUser({ commit }, pl) {
		return new Promise(function (resolve, reject) {
			$api
				.post("player/register", pl)
				.then(function (res) {
					if (res.status === 201) {
						resolve({
							success: true,
							data: res.data
						});
					}
				})
				.catch(function (err) {
					resolve({
						success: false,
						data: err.response.data
					});
				});
		});
	},
	updateProfile({ rootGetters, dispatch }, pl) {
		return new Promise(function (resolve) {
			$api
				.post("auth/profile", pl, {
					headers: {
						Authorization: rootGetters["auth/bearer_token"],
					},
				})
				.then(function (res) {
					if (res.status == 200) {
						resolve(res);
					}
				})

				.catch(function (err) {
					if (err.response.status == 401) {
						dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
					}
					if (err.response.status == 422) {
						Swal.fire({
							icon: "error",
							title: "Failed",
							text: err.response.data.message,
							confirmButtonColor: "#f46a6a",
						});
						console.log(err);
						resolve(err.response);
					}
				});
		});
	},

	convertPoints({ rootGetters, commit, getters }) {
		return new Promise(function (resolve) {
			$api
				.post(`${rootGetters['auth/profile'].role == 'player' ? 'player/points/convert' : 'agent/commissions/convert'}`, {}, {
					headers: { Authorization: getters.bearer_token },
				})

				.then(function (res) {
					if (res.status === 200) {
						resolve({
							success: true,
							data: res.data
						});
					}
				})
				.catch(function (err) {
					if (err.response && err.response.status == 401) {
						commit("destroyAuth");
					}
					resolve({
						success: false,
						data: err.response.data
					});
				});
		});
	},
	checkUsername({ commit, getters }, username) {
		return new Promise(function (resolve) {
			$api
				.get(`player/username?username=${username}`, {
					headers: { Authorization: getters.bearer_token },
				})
				.then(function (res) {
					if (res.status === 200) {
						resolve({
							success: true,
							data: res.data
						});
					}
				})
				.catch(function (err) {
					if (err.response && err.response.status == 401) {
						commit("destroyAuth");
					}
					resolve({
						success: false,
						data: err.response.data
					});
				});
		});
	},
};

export const mutations = {
	triggerLoader(state, value) {
		state.profile.loading = Boolean(value);
		state.profile.routeTo = value ? value : null;
	},
	setToken(state, data) {
		var token = data.access_token; //`${data.token_type} ${data.access_token}`;
		$cookies.set("token", token);
		state.token = token;
		window.PusherChannel = pusher.subscribe(token.split("|")[0]);
	},
	setProfile(state, data) {
		if (!Boolean(window.PusherChannel)) {
			window.PusherChannel = pusher.subscribe(state.token.split("|")[0]);
		}
		state.profile.username = data.username;
		state.profile.role = data.role;
		state.profile.type = data.type;
		state.profile.transfer_in_game = data.transfer_in_game;
		state.profile.status = data.status;
		state.profile.last_login_at = data.last_login_at;
		state.profile.last_login_ip = data.last_login_ip;
		state.profile.withdraw.pin = data.withdraw.pin;
		state.profile.withdraw.password = data.withdraw.password;

		if (data.role == 'player') {
			state.profile.balance = data.balance;
			state.profile.points = data.points;

			state.profile.ticket.unread = data.ticket.unread;
			state.profile.coupon.unclaimed = data.coupon.unclaimed;
		} else {
			state.profile.balance = data["agent.assets.balance"];
			state.profile.points = data["agent.assets.commissions"];
		}

		if (data.username == 'online-player-06') {
			state.profile.menu.table = 'kplay';
			state.profile.menu.slots = 'kplay';
		} else {
			state.profile.menu.table = data.menu.table;
			state.profile.menu.slots = data.menu.slots;
		}
	},
	destroyAuth(state) {
		$cookies.remove("token");
		if (state.token) {
			pusher.unsubscribe(state.token.split('|')[0]);
		}
		state.token = null;
		state.profile.username = null;
		state.profile.role = null;
		state.profile.type = null;
		state.profile.transfer_in_game = false;
		state.profile.status = null;
		state.profile.last_login_at = null;
		state.profile.last_login_ip = null;
		state.profile.balance = 0;
		state.profile.points = 0;
		state.profile.withdraw.pin = false;
		state.profile.withdraw.password = false;
		state.profile.ticket.unread = 0;
		state.profile.coupon.unclaimed = 0;
		state.profile.menu.table = "honorlink";
		state.profile.menu.slots = "honorlink";
		router.replace({ path: "/" }).catch(() => { });
	},
};
export const getters = {
	authenticated(state) {
		return state.token != null;
	},
	bearer_token(state) {
		return "Bearer " + state.token;
	},
	pusher_channel(state) {
		return state.token.split("|")[0];
	},
	profile(state) {
		return state.profile;
	},
	role(state) {
		return state.profile.role;
	},
};
