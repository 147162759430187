<template>
    <div
        id="notice-popup"
        class="popup-bg">
        <div class="popup">
            <div class="popup-header">
                <a
                    class="popup-close"
                    href="javascript:void(0);"
                    @click="closePopup('notice-popup')">
                </a>
            </div>
            <div class="popup-details">
                <div class="popup-content">
                    <div class="title">
                        NOTICE
                        <div class="sub-title">공지사항</div>
                    </div>

                    <div class="uk-modal-body">
                        <div
                            class="content w-ba pt-5"
                            style="margin-top: 1em">
                            <div
                                style="
                                    overflow: hidden;
                                    position: relative;
                                    display: block;
                                "
                                id="event_wrapper">
                                <div class="board-panel event">
                                    <table width="100%">
                                        <colgroup>
                                            <col width="50%" />
                                            <col width="50%" />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th class="title text-center">
                                                    내용
                                                </th>
                                                <th class="title text-center">
                                                    <div
                                                        style="
                                                            vertical-align: inherit;
                                                        ">
                                                        날짜
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="r in data.list"
                                                :key="r._id">
                                                <td
                                                    class="text-center"
                                                    style="cursor: pointer"
                                                    @click="view(r._id)">
                                                    {{ r.subject }}
                                                </td>
                                                <td class="text-center">
                                                    {{ r.created_at }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="mt-2"></div>
                                    <div
                                        class="form-group"
                                        style="
                                            margin-top: 20px;
                                            text-align: center;
                                        "
                                        v-if="data.list.length < 0">
                                        <div
                                            class="form-footer dflex-ac-jc pagination text-base">
                                            <div>No history</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        :data="data"
                        @emitPage="initList"
                        class="w-full"
                        v-if="data.list.length" />
                </div>
            </div>
        </div>
    </div>
    <ViewNoticePopup :data="announcement" />
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ViewNoticePopup from '@/components/ViewNoticePopup';
import ui from '@/mixins/ui';
import Pagination from '@/components/Pagination';
export default {
    components: {
        Pagination,
        ViewNoticePopup,
    },
    mixins: [ui],
    data() {
        return {
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
            },
            announcement: {
                content: '',
                subject: '',
                user: {},
                created_at: '',
            },
            loading: false,
        };
    },
    computed: {
        ...mapGetters('auth', ['authenticated', 'profile']),
    },
    watch: {
        'profile.role'(role) {
            if (role) {
                this.initList(1);
            }
        },
    },
    methods: {
        ...mapActions('announcement', {
            getAnnouncementList: 'getList',
            viewAnnouncement: 'view',
        }),
        async initList(p) {
            var pl = {
                page_no: p,
            };
            this.loading = true;
            const res = await this.getAnnouncementList(pl);

            this.data.list = res.data;
            this.data.links = res.links;
            this.data.current_page = res.current_page;
            this.data.per_page = res.per_page;
            this.data.last_page = res.last_page;
            this.loading = false;
        },

        async view(id) {
            this.openPopup('view-notice-popup');
            const data = await this.viewAnnouncement(id);
            this._id = data._id;
            this.announcement.subject = data.subject;
            this.announcement.content = data.content;
            this.announcement.user.name = data.user.name;
            this.announcement.user.username = data.user.username;
            this.announcement.created_at = data.created_at;
        },
    },
    mounted() {
        if (this.profile.role) {
            this.initList(1);
        }
    },
};
</script>
