import $api from "../api";

export const actions = {
    getList({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`${rootGetters['auth/profile'].role}/transactions?${pl.page_no ? "page=" + pl.page_no : ""}${"&type=" + pl.type}${pl.limit ? "&limit=" + pl.limit : "&limit=50"}`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logoutUser", {}, { root: true }).then(() => { });
                    }
                });
        });
    },
    deposit({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .post(`${rootGetters['auth/profile'].role}/transaction/deposit`, pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            message: res.data.message
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logout", {}, { root: true }).then(() => { });
                    }
                    else {
                        resolve({
                            success: false,
                            message: err.response.data.message
                        });
                    }
                });
        });
    },
    withdraw({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .post(`${rootGetters['auth/profile'].role}/transaction/withdraw`, pl, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve({
                            success: true,
                            message: res.data.message
                        });
                    }
                })
                .catch(function (err) {
                    if (err.response.status == 401) {
                        dispatch("auth/logout", {}, { root: true }).then(() => { });
                    }
                    else {
                        resolve({
                            success: false,
                            message: err.response.data.message
                        });
                    }
                });
        });
    },
    getBank({ rootGetters, dispatch }, pl) {
        return new Promise(function (resolve) {
            $api
                .get(`${rootGetters['auth/profile'].role}/transaction/bank`, {
                    headers: {
                        Authorization: rootGetters["auth/bearer_token"],
                    },
                })
                .then(function (res) {
                    if (res.status == 200) {
                        resolve(res.data.data);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    if (err.response.status == 401) {
                        dispatch("auth/logout", {}, { root: true }).then(() => { });
                    }
                    if (err.response.status == 422) {
                        console.log(err);
                        resolve(err.response);
                    }
                });
        });
    },
};
