<template>
    <ul class="pagination">
        <li
            :class="`page-item  ${
                l.label == '...' || l.active ? 'disabled' : ''
            }`"
            v-for="l in data.links"
            :key="l.label">
            <a
                href="javascript:void(0)"
                @click.prevent="emitPage(l.label)"
                :class="`page-link ${l.active ? 'active' : ''}`">
                {{
                    l.label.includes('Previous')
                        ? '&laquo;'
                        : l.label.includes('Next')
                        ? '&raquo;'
                        : l.label
                }}</a
            >
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
        },
    },
    methods: {
        emitPage(page) {
            if (page.includes('Previous') || page.includes('previous')) {
                if (this.data.current_page > 1) {
                    this.$emit('emitPage', this.data.current_page - 1);
                }
            } else if (page.includes('Next') || page.includes('next')) {
                if (this.data.current_page < this.data.last_page) {
                    this.$emit('emitPage', this.data.current_page + 1);
                }
            } else {
                this.$emit('emitPage', parseInt(page));
            }
        },
    },
};
</script>
