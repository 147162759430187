<template>
    <router-view />
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            intervalId: null,
            timeoutID: null,
            idleDuration: 3600000,
        };
    },

    computed: {
        ...mapGetters('auth', ['authenticated']),
    },
    watch: {
        authenticated(n) {
            if (!n) {
                this.stopInterval();
            } else {
                this.startInterval();
            }
        },
    },
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
            authLogout: 'logoutUser',
        }),
        async profileInterval() {
            await this.authGetProfile();
        },
        startInterval() {
            this.intervalId = setInterval(this.profileInterval, 10000);
        },
        stopInterval() {
            clearInterval(this.intervalId);
        },
        resetTimer() {
            clearTimeout(this.timeoutID);
            this.timeoutID = setTimeout(this.callLogout, this.idleDuration);
        },
        callLogout() {
            if (this.authenticated && process.env.NODE_ENV == 'production') {
                this.authLogout();
            }
        },
    },
    mounted() {
        var vm = this;
        if (vm.authenticated) {
            vm.startInterval();
        }
        window.addEventListener('message', function (e) {
            if (e.origin === window.origin) {
                if (e.data.event == 'close-game' && !window.opener) {
                    vm.authGetProfile();
                    vm.$store.commit('closeWindow', e.data.name);
                }
                if (e.data.event == 'open-game' && !window.opener) {
                    // vm.$store.commit("setPopups");
                    vm.$store.commit('addWindow', {
                        popup: e.data.name,
                        gameType: e.data.gameType,
                    });
                }
            }
        });
        // window.addEventListener('beforeunload', this.confirm_leaving)
        // window.addEventListener('beforeunload', (event) => {
        //     event.preventDefault();
        //     this.authLogout();
        //     event.returnValue = '';
        // });
        this.resetTimer();
        document.addEventListener('mousemove', this.resetTimer);
        document.addEventListener('keypress', this.resetTimer);
    },
    beforeUnmount() {
        this.stopInterval();
        document.removeEventListener('mousemove', this.resetTimer);
        document.removeEventListener('keypress', this.resetTimer);
    },
};
</script>
<style></style>
