<template>
    <div
        id="create-ticket-popup"
        class="popup-bg">
        <div
            class="popup"
            style="width: 500px">
            <div class="popup-header">
                <a
                    href="javascript:void(0);"
                    class="popup-close"
                    @click="
                        closePopup('create-ticket-popup');
                        reset();
                    ">
                </a>
            </div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="title">
                        INQUIRY
                        <div class="sub-title">문의</div>
                    </div>

                    <div class="uk-modal-body">
                        <form
                            class="ui form equal width popup-form"
                            @submit.prevent="submitTicket">
                            <div class="field required">
                                <label>카테고리</label>
                                <select
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.category.$error,
                                    }"
                                    v-model="pl.category">
                                    <option
                                        :value="null"
                                        selected
                                        disabled
                                        hidden>
                                        카테고리 선택
                                    </option>
                                    <option
                                        :value="r"
                                        v-for="(r, i) in category_options"
                                        :key="i">
                                        {{ $t(r) }}
                                    </option>
                                </select>

                                <div
                                    v-for="(item, index) in v$.pl.category
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                            <div class="field required">
                                <label>주제</label>
                                <input
                                    type="text"
                                    id="mb_subject"
                                    name="mb_subject"
                                    placeholder="제목 입력"
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.subject.$error,
                                    }"
                                    v-model="pl.subject" />
                                <div
                                    v-for="(item, index) in v$.pl.subject
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                            <div class="field required">
                                <label>내용</label>
                                <textarea
                                    :class="{
                                        'is-invalid':
                                            submitted && v$.pl.content.$error,
                                    }"
                                    placeholder="여기에 내용 입력"
                                    v-model="pl.content"></textarea>
                                <div
                                    v-for="(item, index) in v$.pl.content
                                        .$errors"
                                    :key="index"
                                    class="invalid-feedback">
                                    <small v-if="item.$message">
                                        {{ $t(item.$message) }}
                                    </small>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <div class="action">
                    <button
                        type="button"
                        class="hover-link red"
                        @click="submitTicket">
                        <span class="os-cont">보내기</span>
                    </button>
                    <button
                        class="hover-link"
                        @click="closePopup('create-ticket-popup')">
                        <span class="os-cont">취소</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ui from '@/mixins/ui';
import { required, minValue, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            category_options: [
                'Inquiry of Deposit Accounts',
                'Charge and Exchange Inquiry',
                'System Error',
                'Game Error',
                'Other Inquiries',
            ],
            submitted: false,
            pl: {
                category: null,
                subject: null,
                content: null,
            },
        };
    },
    validations() {
        return {
            pl: {
                category: {
                    required: helpers.withMessage('select a category', required),
                },
                subject: {
                    required: helpers.withMessage('required', required),
                },
                content: {
                    required: helpers.withMessage('required', required),
                },
            },
        };
    },
    mixins: [ui],
    methods: {
        ...mapActions('ticket', {
            ticketCreate: 'createTicket',
        }),
        close() {
            this.closePopup('create-ticket-popup');
            this.reset();
        },
        async submitTicket() {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.pl.$invalid) {
                return;
            } else {
                // const confirmed = await Swal.fire({
                //     title: 'Are you sure?',
                //     type: 'warning',
                //     icon: 'question',
                //     showCancelButton: true,
                //     padding: '2em',
                // }).then((result) => {
                //     return result.isConfirmed;
                // });
                // if (confirmed) {}
                var fd = new FormData();
                fd.append('category', this.pl.category);
                fd.append('subject', this.pl.subject);
                fd.append('content', this.pl.content);
                const res = await this.ticketCreate(fd);

                if (res.success) {
                    this.closePopup('create-ticket-popup');
                    this.reset();
                    this.$emit('emitPage', 1);
                    Swal.fire({
                        title: this.$t(res.data.message),
                        icon: res.data.status,
                        timer: res.success ? 2000 : null,
                        confirmButtonText: this.$t('ok'),
                    });
                } else {
                    Swal.fire({
                        title: this.$t(res.data.message),
                        icon: res.data.status,
                        timer: res.success ? 2000 : null,
                        confirmButtonText: this.$t('ok'),
                    });
                }
            }
        },
        reset() {
            this.pl.category = null;
            this.pl.subject = null;
            this.pl.content = null;
            this.v$.pl.$reset();
        },
    },
    mounted() {
        var vm = this;
        let popupBg = document.getElementById('create-ticket-popup');
        if (popupBg) {
            popupBg.addEventListener('mousedown', function (e) {
                e = window.event || e;
                if (this === e.target) {
                    vm.reset();
                }
            });
        }
    },
};
</script>
