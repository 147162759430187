<template>
  <div id="view-notice-popup" class="popup-bg">
    <div class="popup">
      <div class="popup-header">
        <a
          href="javascript:void(0);"
          @click="closePopup('view-notice-popup')"
          class="popup-close"
        >
        </a>
      </div>
      <div class="popup-details">
        <div class="popup-content">
          <div class="title">
            NOTICE
            <div class="sub-title">공지사항 보기 </div>
          </div>

          <div class="uk-modal-body">
            <div class="content w-ba pt-5" style="margin-top: 1em">
              <div
                style="overflow: hidden; position: relative; display: block"
                id="event_wrapper"
              >
                <div class="board-panel event">
                  <table width="100%">
                    <tbody>
                      <h2>
                        {{ data.subject }}
                      </h2>
                      <div style="font-size: 0.8rem" class="text-info">
                        {{ data.user.username }}
                      </div>
                      <div style="font-size: 0.8rem">
                        {{ data.created_at }}
                      </div>
                      <div class="mt-5" style="color: #fff">
                        <div v-html="data.content"></div>
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ui from "@/mixins/ui";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Announcement View",
  components: {},
  mixins: [ui],
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "profile"]),
  },
  methods: {
    ...mapActions("auth", {
      authGetProfile: "getProfile",
    }),
  },
  mounted() {
    // console.log(this.data)
  },
};
</script>
