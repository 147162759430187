<template>
    <div
        id="coupon-popup"
        class="popup-bg">
        <div class="popup">
            <div class="popup-header">
                <a
                    href="javascript:void(0);"
                    class="popup-close"
                    @click="closePopup('coupon-popup')">
                    <!-- <img
                        src="@/assets/img/icon-close.png"
                        alt="icon-close" /> -->
                </a>
            </div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="title">
                        COUPON
                        <div class="sub-title">쿠폰</div>
                    </div>

                    <div class="uk-modal-body">
                        <div
                            class="content w-ba pt-5"
                            style="margin-top: 1em">
                            <div
                                style="
                                    overflow: hidden;
                                    position: relative;
                                    display: block;
                                "
                                id="event_wrapper">
                                <div class="board-panel event">
                                    <table width="100%">
                                        <!-- <colgroup>
                                            <col width="10%" />
                                            <col width="10%" />
                                            <col width="25%" />
                                            <col width="25%" />
                                            <col width="25%" />
                                        </colgroup> -->
                                        <thead>
                                            <tr>
                                                <th class="title text-center">
                                                    코드
                                                </th>
                                                <th class="title text-center">
                                                    금액
                                                </th>
                                                <th class="title text-center">
                                                    상태
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(r, index) in data.list"
                                                :key="index">
                                                <td
                                                    class="text-center"
                                                    :class="
                                                        r.status == 'unclaimed'
                                                            ? 'cursor-pointer'
                                                            : null
                                                    "
                                                    @click="
                                                        r.status == 'unclaimed'
                                                            ? claim(r._id)
                                                            : null
                                                    ">
                                                    {{ r.code }}
                                                </td>
                                                <td
                                                    class="text-center"
                                                    style="
                                                        color: rgb(204 204 204);
                                                    ">
                                                    {{ numberString(r.amount) }}
                                                </td>
                                                <td class="text-center">
                                                    <button
                                                        v-if="
                                                            r.status !=
                                                            'claimed'
                                                        "
                                                        type="button"
                                                        class="hover-link btn"
                                                        style="min-height: 23px"
                                                        @click="claim(r._id)">
                                                        {{ $t('issue') }}
                                                    </button>
                                                    <span
                                                        v-else
                                                        :class="{
                                                            'text-info':
                                                                r.status ==
                                                                'unclaimed',
                                                            'text-success fw-bold':
                                                                r.status ==
                                                                'claimed',
                                                            'text-danger':
                                                                r.status ==
                                                                'expired',
                                                        }"
                                                        >{{
                                                            $t(r.status)
                                                        }}</span
                                                    >
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div
                                        class="form-group"
                                        style="
                                            margin-top: 20px;
                                            text-align: center;
                                        "
                                        v-if="data.list.length <= 0">
                                        <div
                                            class="form-footer dflex-ac-jc pagination text-base">
                                            <div>내역없음</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        :data="data"
                        @emitPage="initList"
                        class="w-full"
                        v-if="data.list.length" />
                </div>
            </div>
        </div>
    </div>
    <ViewNoticePopup :data="announcement" />
</template>

<script>
import Layout from '@/components/Layout';
// import Loader from "@/components/Loader.vue";
import Pagination from '@/components/Pagination';
import ui from '@/mixins/ui';
import datetime from '@/mixins/datetime';
import number from '@/mixins/number';
import { mapActions, mapGetters } from 'vuex';
import Swal from 'sweetalert2';

export default {
    name: 'Coupon',
    components: {
        Layout,
        Pagination,
    },
    mixins: [ui, datetime, number],
    data() {
        return {
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
        };
    },
    computed: {
        ...mapGetters('auth', ['authenticated', 'profile']),
    },
    props: {
        trigger: {
            type: Number,
        },
    },
    watch: {
        'profile.role'(role) {
            if (role) {
                this.initList(1);
            }
        },
        trigger(n) {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
        }),
        ...mapActions('coupon', {
            couponGetList: 'getList',
            claimCoupon: 'getCoupon',
        }),
        async initList(p) {
            var pl = {
                page_no: p ? p : 1,
                limit: 50,
            };

            this.loading = true;
            const data = await this.couponGetList(pl);

            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;

            this.loading = false;
            //   this.openPopup("couponPopUpEventModal");
        },
        async claim(_id) {
            // const confirmed = await Swal.fire({
            //     title: 'Are you sure?',
            //     text: 'This will claim the coupon.',
            //     type: 'warning',
            //     icon: 'question',
            //     showCancelButton: true,
            //     padding: '2em',
            // }).then((result) => {
            //     return result.isConfirmed;
            // });
            // if (confirmed) {
            const res = await this.claimCoupon(_id);
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    title: this.$t(res.data.message),
                    confirmButtonText: this.$t('ok'),
                });
                this.authGetProfile();
                this.initList(1);
            } else {
                Swal.fire({
                    icon: 'failed',
                    title: this.$t(res.data.message),
                    confirmButtonText: this.$t('ok'),
                });
            }
            // }
        },
    },
    mounted() {
        if (this.profile.role) {
            this.initList(1);
        }
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.bind('coupon', (e) => {
                this.initList(1);
                this.authGetProfile();
            });
        }
    },
    beforeUnmount() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('coupon');
        }
    },
};
</script>
