<template>
    <div
        id="login"
        class="popup-bg">
        <div class="popup">
            <a
                href="javascript:void(0);"
                class="popup-close"
                @click="closePopup('login')">
            </a>
            <div class="popup-body">
                <div class="logo-wrapper">
                    <img
                        src="@/assets/img/plu777/logo.png"
                        alt="logo" />
                </div>

                <form
                    class="ngdialog-login-page"
                    @submit.prevent="playerLogin">
                    <input
                        type="text"
                        placeholder="아이디"
                        v-model="username" />
                    <input
                        type="password"
                        placeholder="비밀번호"
                        v-model="password" />
                    <button
                        type="submit"
                        class="btn-yellow">
                        로그인
                    </button>
                </form>
                <p
                    class="register-link"
                    @click="
                        closePopup('login');
                        openPopup('register');
                    ">
                    아직 계정이 없으신가요? <span>신규 계정 생성</span>
                </p>
            </div>
        </div>
    </div>
    <!-- <RegisterPopup /> -->
</template>

<script>
import Swal from 'sweetalert2';
import RegisterPopup from '@/components/RegisterPopup';
import ui from '../mixins/ui';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { mapActions } from 'vuex';
// import $cookies from "vue-cookies";
export default {
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    components: {
        RegisterPopup,
    },
    mixins: [ui],
    data() {
        return {
            username: null,
            password: null,
            submitted: false,
            loading: false,
            alert: false,
        };
    },
    validations() {
        return {
            username: {
                required: helpers.withMessage(this.$t('required'), required),
            },
            password: {
                required: helpers.withMessage(this.$t('required'), required),
            },
        };
    },
    methods: {
        ...mapActions('auth', {
            authLogin: 'loginUser',
            authGetProfile: 'getProfile',
        }),
        async playerLogin() {
            this.v$.$touch();
            this.submitted = true;
            this.error_message = null;

            if (this.v$.$invalid) {
                return;
            } else {
                this.loading = true;
                var fd = new FormData();
                fd.append('username', this.username);
                fd.append('password', this.password);
                const res = await this.authLogin(fd);
                if (res.success) {
                    this.$router.replace({ path: '/' }).catch(() => {});
                    this.closePopup('login');
                    this.reset();
                    this.authGetProfile();
                    //   this.alert = true;
                } else {
                    this.alert = true;
                    this.loading = false;
                    this.error_message = res.data.message;
                    //   this.auth_attempts += 1;
                    //   $cookies.set("auth_attempts", this.auth_attempts);
                }
                Swal.fire({
                    icon: res.data.status,
                    title: this.$t(res.data.message),
                    confirmButtonText: this.$t('ok'),
                });
            }
        },
        reset() {
            this.username = null;
            this.password = null;
            this.v$.$reset();
        },
    },
    mounted() {
        var vm = this;
        let popupBg = document.getElementById('login');
        if (popupBg) {
            popupBg.addEventListener('mousedown', function (e) {
                e = window.event || e;
                if (this === e.target) {
                    vm.reset();
                }
            });
        }
    },
};
</script>

<style></style>
