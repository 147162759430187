<template>
    <div
        id="slot1"
        class="popup-bg games">
        <div class="popup">
            <div class="popup-header">
                <div class="title">슬롯게임</div>
                <a
                    href="javascript:void(0);"
                    class="popup-close"
                    @click="closePopup('slot1')">
                </a>
            </div>
            <div class="ngdialog-content">
                <div class="ngdialog-games-page">
                    <div class="game-button-container live live_list">
                        <div
                            class="game-buttons play_live"
                            v-for="(r, index) in data.list"
                            :key="index">
                            <img
                                class="game"
                                :src="
                                    require('@/assets/img/thumbnail/slot/' +
                                        r.game_thumbnail)
                                "
                                alt="" />

                            <span class="title">{{ r.name }}</span>
                            <button
                                type="button"
                                :disabled="loading"
                                @click="showGameDetailsSlot(r)"
                                class="btn-yellow btn-play">
                                게임입장
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ui from '@/mixins/ui';
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            loading: false,
            data: {
                list: [],
                links: [],
                current_page: 0,
                last_page: 0,
                per_page: 0,
                total: 0,
            },
        };
    },
    mixins: [ui],
    computed: {
        ...mapGetters('auth', ['authenticated']),
        ...mapState('slotGame', {
            slot_game: 'data',
        }),
    },
    methods: {
        ...mapActions('slotGame', {
            getSlotGame: 'getList',
            slotGameLaunch: 'getGameLaunch',
        }),

        async initList() {
            const res = await this.getSlotGame('slot_lobby');
            this.data.list = res.data;
        },
        async showGameDetailsSlot(data) {
            if (!this.authenticated) return;
            const pl = {
                game_id: data.id,
                vendor: data.vendor,
            };

            switch (pl.vendor) {
                case 'Dragoon Soft':
                case 'Blueprint Gaming':
                case 'GameArt':
                case 'Habanero':
                case 'Mobilots':
                case 'PlayPearls':
                case 'PlayStar':
                case 'Thunderkick':
                case 'Wazdan':
                case 'CQ9':
                case 'netent':
                case 'PragmaticPlay':
                case 'redtiger':
                case 'EVOPLAY':
                case 'dreamtech':
                    this.$router.push({
                        path: `/games/slot_detail`,
                        query: {
                            vendor: pl.vendor,
                            game_id: pl.game_id,
                        },
                    });
                    break;
                default:
                    if (this.oneGameAtATime) {
                        Swal.fire({
                            title: this.$t(
                                'To proceed, please close the game that is currently in progress.'
                            ),
                            type: 'error',
                            icon: 'error',
                            confirmButtonText: this.$t('ok'),
                        });
                        return;
                    }
                    this.loading = true;
                    const res = await this.slotGameLaunch(pl);
                    this.loading = false;
                    if (res.status == 200) {
                        var queryParams = {
                            url: res.data.data.url,
                        };

                        var queryString = Object.keys(queryParams)
                            .map(
                                (key) =>
                                    key +
                                    '=' +
                                    encodeURIComponent(queryParams[key])
                            )
                            .join('&');
                        var window_name =
                            this.gameLaunchMode === 'single' ? 'game' : data.id;
                        const isMobile = {
                            Android: function () {
                                return navigator.userAgent.match(/Android/i);
                            },
                            BlackBerry: function () {
                                return navigator.userAgent.match(/BlackBerry/i);
                            },
                            iOS: function () {
                                return navigator.userAgent.match(
                                    /iPhone|iPad|iPod/i
                                );
                            },
                            Opera: function () {
                                return navigator.userAgent.match(/Opera Mini/i);
                            },
                            Windows: function () {
                                return (
                                    navigator.userAgent.match(/IEMobile/i) ||
                                    navigator.userAgent.match(/WPDesktop/i)
                                );
                            },
                            any: function () {
                                return (
                                    isMobile.Android() ||
                                    isMobile.BlackBerry() ||
                                    isMobile.iOS() ||
                                    isMobile.Opera() ||
                                    isMobile.Windows()
                                );
                            },
                        };
                        try {
                            if (isMobile.any()) {
                                this.current_window = window.location.replace(
                                    `/game-launch/slot?${queryString}`,
                                    window_name,
                                    'width=' +
                                        screen.availWidth +
                                        ',height=' +
                                        screen.availHeight +
                                        ',fullscreen=yes, toolbar=no, location=no, directories=no, status=no, menubar=no,scrollbars=no,resizable=no'
                                );
                            } else {
                                this.current_window = window.open(
                                    `/game-launch/slot?${queryString}`,
                                    window_name,
                                    'width=' +
                                        screen.availWidth +
                                        ',height=' +
                                        screen.availHeight +
                                        ',fullscreen=yes, toolbar=no, location=no, directories=no, status=no, menubar=no,scrollbars=no,resizable=no'
                                );
                            }
                        } catch (e) {}
                        // this.current_window = window.open(
                        //     `/game-launch/slot?${queryString}`,
                        //     window_name,
                        //     'width=' +
                        //         screen.availWidth +
                        //         ',height=' +
                        //         screen.availHeight +
                        //         ',fullscreen=yes, toolbar=no, location=no, directories=no, status=no, menubar=no,scrollbars=no,resizable=no'
                        // );
                    } else if (res.status == 401) {
                        this.$router.replace({ path: '/' }).catch(() => {});
                    }
            }
        },
    },
    mounted() {
        this.initList();
    },
};
</script>
<style></style>
