<template>
    <header>
        <div class="top">
            <div class="logo-wrapper">
                <router-link to="/">
                    <img
                        class="logo"
                        src="@/assets/img/plu777/logo.png"
                        alt="logo" />
                </router-link>
            </div>
            <div class="container">
                <div
                    class="action-wrapper"
                    v-if="!authenticated">
                    <form @submit.prevent="playerLogin">
                        <input
                            type="text"
                            placeholder="아이디"
                            v-model="pl.username" />
                        <input
                            type="password"
                            placeholder="비밀번호"
                            v-model="pl.password" />
                        <button
                            type="submit"
                            class="btn-framed"
                            :disabled="isBtnDisabled">
                            로그인
                        </button>
                        <button
                            type="button"
                            class="btn-framed"
                            @click="openPopup('register')">
                            회원가입
                        </button>
                    </form>
                </div>
                <div
                    class="action-wrapper"
                    v-if="authenticated">
                    <form>
                        <div
                            @click="goToProfile"
                            style="cursor: pointer">
                            <span class="label">
                                <img
                                    style="width: 15px"
                                    src="@/assets/img/user-icon.svg"
                                    alt=""
                            /></span>
                            <span
                                class="value"
                                style="color: #999999"
                                >{{ profile.username }}</span
                            >
                        </div>
                        <div
                            class="money"
                            @click="goToTransaction"
                            style="cursor: pointer">
                            <span class="label">
                                <img
                                    style="width: 15px"
                                    src="@/assets/img/wallet-icon.svg"
                                    alt=""
                            /></span>
                            <span class="value">{{ myBalance }}</span>
                            <span
                                class="game-indicator"
                                v-if="this.$store.state.popups.length > 0 || this.profile.transfer_in_game "
                                >IN-GAME</span
                            >
                            <img
                                v-if="this.$store.state.launching_game"
                                src="@/assets/img/small-loader.svg"
                                alt="" />
                        </div>
                        <div class="money">
                            <span class="label">
                                <img
                                    style="width: 15px"
                                    src="@/assets/img/point-icon.svg"
                                    alt=""
                            /></span>
                            <span class="value text-info">{{
                                numberString(profile.points)
                            }}</span>
                            <a
                                href="javascript:void(0)"
                                class="dropdown"
                                @click="convertPoints"
                                >포인트전환</a
                            >
                            <!-- <span
                                class="game-indicator"
                                v-if="this.$store.state.popups.length > 0"
                                >(IN GAME)</span
                            > -->
                        </div>
                        <button
                            class="btn-framed"
                            type="button"
                            @click="logout"
                            :disabled="isBtnDisabled">
                            로그아웃
                        </button>
                    </form>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="container">
                <div class="nav-wrapper">
                    <nav>
                        <ul>
                            <li>
                                <router-link
                                    to="/vendors/casino"
                                    v-if="
                                        ['all', 'honorlink'].includes(
                                            profile.menu.table
                                        )
                                    ">
                                    <p>라이브카지노</p>
                                    <p>LIVE CASINO</p>
                                </router-link>
                                <router-link
                                    to="/vendors/casino2"
                                    v-if="
                                        ['all', 'kplay'].includes(
                                            profile.menu.table
                                        )
                                    ">
                                    <p>라이브카지노</p>
                                    <p>LIVE CASINO</p>
                                </router-link>
                                <!-- <a
                                    href="javascript:void(0);"
                                    v-if="
                                        ['all', 'honorlink'].includes(
                                            profile.menu.table
                                        )
                                    "
                                    @click="openPopup('casino1')">
                                    <p>라이브카지노</p>
                                    <p>LIVE CASINO</p>
                                </a>
                                <a
                                    href="javascript:void(0);"
                                    v-if="
                                        ['all', 'kplay'].includes(
                                            profile.menu.table
                                        ) && hideGamesTwo == true
                                    "
                                    @click="openPopup('casino2')">
                                    <p>라이브카지노</p>
                                    <p>LIVE CASINO</p>
                                </a> -->
                            </li>
                            <li>
                                <router-link
                                    to="/vendors/slot"
                                    v-if="
                                        ['all', 'honorlink'].includes(
                                            profile.menu.table
                                        )
                                    ">
                                    <p>슬롯게임</p>
                                    <p>SLOT GAME</p>
                                </router-link>
                                <router-link
                                    to="/vendors/slot2"
                                    v-if="
                                        ['all', 'kplay'].includes(
                                            profile.menu.table
                                        )
                                    ">
                                    <p>슬롯게임</p>
                                    <p>SLOT GAME</p>
                                </router-link>
                                <!-- <a
                                    href="javascript:void(0);"
                                    v-if="
                                        ['all', 'honorlink'].includes(
                                            profile.menu.slots
                                        )
                                    "
                                    @click="openPopup('slot1')">
                                    <p>슬롯게임</p>
                                    <p>SLOT GAME</p>
                                </a>
                                <a
                                    href="javascript:void(0);"
                                    v-if="
                                        ['all', 'kplay'].includes(
                                            profile.menu.slots
                                        ) && hideGamesTwo == true
                                    "
                                    class="dropdown-item"
                                    @click="openPopup('slot2')">
                                    <p>슬롯게임</p>
                                    <p>SLOT GAME</p>
                                </a> -->
                                <!-- <a href="javascript:void(0);">
                                    <p>슬롯게임</p>
                                    <p>SLOT GAME</p>
                                </a> -->
                                <!-- <div class="dropdown">
                                    <div
                                        v-if="
                                            ['all', 'honorlink'].includes(
                                                profile.menu.slots
                                            )
                                        "
                                        class="dropdown-item"
                                        @click="openPopup('slot1')">
                                        <p>슬롯게임</p>
                                        <p>SLOT GAME </p>
                                    </div>
                                    <div
                                        v-if="
                                            ['all', 'kplay'].includes(
                                                profile.menu.slots
                                            ) && hideGamesTwo == true
                                        "
                                        class="dropdown-item"
                                        @click="openPopup('slot2')">
                                        <p>슬롯게임</p>
                                        <p>SLOT GAME</p>
                                    </div>
                                </div> -->
                            </li>
                            <!-- <li>
                                <a
                                    v-if="
                                        ['all', 'kplay'].includes(
                                            profile.menu.table
                                        )
                                    "
                                    href="javascript:void(0);"
                                    @click="openPopup('casino2')">
                                    <p>라이브카지노</p>
                                    <p>LIVE CASINO 2</p>
                                </a>
                            </li>
                            <li>
                                <a
                                    v-if="
                                        ['all', 'kplay'].includes(
                                            profile.menu.slots
                                        )
                                    "
                                    href="javascript:void(0);"
                                    @click="openPopup('slot2')">
                                    <p>슬롯게임</p>
                                    <p>SLOT GAME 2</p>
                                </a>
                            </li> -->
                            <li>
                                <a
                                    href="javascript:void(0);"
                                    @click="goToBetHistory">
                                    <p>베팅이력</p>
                                    <p>BET HISTORY</p>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0);"
                                    @click="goToNotice">
                                    <p>공지사항</p>
                                    <p>NOTICE</p>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <nav>
                        <ul>
                            <li>
                                <a  
                                    :class="{'link-disabled' : this.profile.type=='offline'}"
                                    href="javascript:void(0);"
                                    @click="goToDeposit">
                                    <p>입금 신청</p>
                                    <p>DEPOSIT</p>
                                </a>
                            </li>
                            <li>
                                <a  
                                    :class="{'link-disabled' : this.profile.type=='offline'}"
                                    href="javascript:void(0);"
                                    @click="goToWithdraw">
                                    <p>출금 신청</p>
                                    <p>WITHDRAW</p>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0);"
                                    @click="goToCoupon">
                                    <span
                                        class="notif-badge"
                                        v-if="profile.coupon.unclaimed"
                                        >{{ profile.coupon.unclaimed }}</span
                                    >
                                    <p>쿠폰</p>
                                    <p>COUPON</p>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="javascript:void(0);"
                                    @click="goToTicket">
                                    <span
                                        class="notif-badge"
                                        v-if="profile.ticket.unread"
                                        >{{ profile.ticket.unread }}</span
                                    >
                                    <p>고객센터</p>
                                    <p>CUSTOMER</p>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <LoginPopup />
    <RegisterPopup />
    <SlotGamesPopup1 />
    <CasinoGamesPopup1 />
    <SlotGamesPopup2 />
    <CasinoGamesPopup2 />
    <!-- <div v-if="authenticated"> -->
    <DepositPopup />
    <WithdrawPopup />
    <TicketPopup :trigger="ticket_trigger" />
    <CouponPopup :trigger="coupon_trigger" />
    <NoticePopup />
    <EventPopup />
    <TransactionPopup />
    <BetHistoryPopup />
    <MyPage />
    <!-- </div> -->
</template>

<script>
import Swal from 'sweetalert2';
import LoginPopup from '@/components/LoginPopup';
import RegisterPopup from '@/components/RegisterPopup';
import SlotGamesPopup1 from '@/components/SlotGamesPopup1';
import CasinoGamesPopup1 from '@/components/CasinoGamesPopup1';
import SlotGamesPopup2 from '@/components/SlotGamesPopup2';
import CasinoGamesPopup2 from '@/components/CasinoGamesPopup2';
import NoticePopup from '@/components/NoticePopup';
import EventPopup from '@/components/EventPopup';
import DepositPopup from '@/components/DepositPopup';
import WithdrawPopup from '@/components/WithdrawPopup';
import CouponPopup from '@/components/CouponPopup';
import TicketPopup from '@/components/TicketPopup';
import TransactionPopup from '@/components/TransactionPopup';
import BetHistoryPopup from '@/components/BetHistoryPopup';
import MyPage from '@/components/MyPage';
import ui from '../mixins/ui';
import number from '../mixins/number';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Header',
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            isBtnDisabled: false,
            pl: {
                username: null,
                password: null,
            },
            submitted: false,
            // hideGamesTwo: null,
            coupon_trigger: 0,
            ticket_trigger: 0,
        };
    },
    validations() {
        return {
            pl: {
                username: {
                    required: helpers.withMessage(
                        this.$t('required'),
                        required
                    ),
                },
                password: {
                    required: helpers.withMessage(
                        this.$t('required'),
                        required
                    ),
                },
            },
        };
    },
    components: {
        LoginPopup,
        RegisterPopup,
        SlotGamesPopup1,
        CasinoGamesPopup1,
        SlotGamesPopup2,
        CasinoGamesPopup2,
        NoticePopup,
        EventPopup,
        DepositPopup,
        WithdrawPopup,
        TicketPopup,
        TransactionPopup,
        BetHistoryPopup,
        CouponPopup,
        MyPage,
    },
    computed: {
        ...mapGetters('auth', ['authenticated', 'profile']),

        hideGamesTwo() {
            return this.authenticated;
        },
    },
    mixins: [ui, number],
    methods: {
        ...mapActions('auth', {
            authLogout: 'logoutUser',
            authGetProfile: 'getProfile',
            authLogin: 'loginUser',
        }),
        async playerLogin() {
            this.v$.pl.$touch();
            this.submitted = true;
            this.error_message = null;

            if (this.v$.pl.$invalid) {
                console.log(this.v$);
                return;
            } else {
                this.loading = true;
                var fd = new FormData();
                fd.append('username', this.pl.username);
                fd.append('password', this.pl.password);
                const res = await this.authLogin(fd);
                if (res.success) {
                    // this.$router.replace({ path: '/' }).catch(() => {});
                    this.closePopup('login');
                    this.reset();
                    this.authGetProfile();
                    // this.hideGamesTwo = true;
                    //   this.alert = true;
                } else {
                    this.alert = true;
                    this.loading = false;
                    this.error_message = res.data.message;
                    //   this.auth_attempts += 1;
                    //   $cookies.set("auth_attempts", this.auth_attempts);
                }
                Swal.fire({
                    icon: res.data.status,
                    title: this.$t(res.data.message),
                    confirmButtonText: this.$t('ok'),
                });
            }
        },
        reset() {
            this.submitted = false;
            this.pl.username = null;
            this.pl.password = null;
            this.v$.pl.$reset();
        },
        goToProfile() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('my-page');
            }
        },
        goToTransaction() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('transaction-popup');
            }
        },
        goToBetHistory() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('bet-history-popup');
            }
        },
        goToDeposit() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                if(this.profile.type=='online') {
                    this.openPopup('deposit-popup');
                }
            }
        },
        goToWithdraw() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                if(this.profile.type=='online') {
                    this.openPopup('withdraw-popup');
                }
            }
        },
        goToTicket() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.ticket_trigger += 1;
                this.openPopup('ticket-popup');
            }
        },
        goToCoupon() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.coupon_trigger += 1;
                this.openPopup('coupon-popup');
            }
        },
        goToNotice() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('notice-popup');
            }
        },
        async logout() {
            this.isBtnDisabled = true;
            const res = await this.authLogout();
            if (res) {
                this.$router.replace({ path: '/' });
            }
            // this.hideGamesTwo = false;
            this.isBtnDisabled = false;
        },
        showAlert() {
            Swal.fire({
                title: '매장에 문의하세요',
                icon: 'info',
                confirmButtonText: this.$t('ok'),
            });
        },
    },
    mounted() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.bind('deposit', (e) => {
                this.authGetProfile();
            });
            window.PusherChannel.bind('withdraw', (e) => {
                this.authGetProfile();
            });
        }
        // this.hideGamesTwo = this.authenticated;
    },
    beforeUnmount() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('deposit');
            window.PusherChannel.unbind('withdraw');
        }
    },
};
</script>
<style></style>
