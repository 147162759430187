<template>
    <div
        id="deposit-popup"
        class="popup-bg">
        <div class="popup">
            <div class="popup-header">
                <a
                    href="javascript:void(0);"
                    class="popup-close"
                    @click="
                        closePopup('deposit-popup');
                        reset();
                    ">
                </a>
            </div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="title">
                        DEPOSIT
                        <div class="sub-title">입금 신청</div>
                    </div>

                    <div
                        action=""
                        class="popup-transaction-form">
                        <div class="getbank-btn-wrapper">
                            <div class="preset-amount">
                                <button
                                    type="button"
                                    class="hover-link btn getbank-btn"
                                    @click="getBankDetails">
                                    <span class="os-cont">계좌정보 보기</span>
                                </button>
                            </div>
                        </div>
                        <div>
                            <label
                                for=""
                                style="display: block"
                                >은행명</label
                            >
                            <input
                                type=" text"
                                placeholder=""
                                disabled
                                v-model="bank_name" />
                        </div>
                        <div>
                            <label
                                for=""
                                style="display: block"
                                >예금주</label
                            >
                            <input
                                type=" text"
                                placeholder=""
                                disabled
                                v-model="account_holder" />
                        </div>
                        <div>
                            <label
                                for=""
                                style="display: block"
                                >계좌번호</label
                            >
                            <input
                                type=" text"
                                placeholder=""
                                disabled
                                v-model="account_number" />
                        </div>
                        <div>
                            <label
                                for=""
                                style="display: block"
                                >금액</label
                            >
                            <VueNumberFormat
                                id="amount"
                                class="number"
                                :class="{
                                    'is-invalid': submitted && v$.amount.$error,
                                }"
                                v-model:value="amount"
                                :options="{
                                    precision: 0,
                                    prefix: '',
                                    suffix: '',
                                    decimal: '.',
                                    thousand: ',',
                                    acceptNegative: false,
                                    isInteger: true,
                                }"></VueNumberFormat>
                            <div
                                v-for="(item, index) in v$.amount.$errors"
                                :key="index"
                                class="invalid-feedback">
                                <small v-if="item.$message">
                                    {{ $t(item.$message) }}
                                </small>
                            </div>
                        </div>
                        <div class="preset-amount">
                            <button
                                v-for="n in preset_amounts"
                                :key="n"
                                type="button"
                                @click="selectAmount(n)"
                                class="hover-link btn"
                                :class="{ 'btn-red': n == 0 }">
                                {{ n == 0 ? $t('clear') : numberString(n) }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="popup-footer">
                    <!-- <div class="action">
                        <button type="button" class="hover-link red">
                            <span class="os-cont">Submit</span>
                        </button>
                        <button class="hover-link" onclick="closePopup('deposit-popup')">
                            <span class="os-cont">Cancel</span>
                        </button> -->
                    <button
                        class="btn-yellow"
                        style="width: 100%; cursor: pointer"
                        :disabled="!bank_details"
                        @click="submit()">
                        입금요청
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ui from '@/mixins/ui';
import number from '@/mixins/number';
import { required, minValue, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            submitted: false,
            preset_amounts: [10000, 50000, 100000, 500000, 1000000, 0],
            bank_name: null,
            account_holder: null,
            account_number: null,
            amount: 0,
        };
    },
    computed: {
        bank_details() {
            return this.bank_name && this.account_holder && this.account_number;
        },
    },
    validations() {
        return {
            amount: {
                required: helpers.withMessage('required', required),
                minValueValue: helpers.withMessage(
                    'the minimum deposit application amount is 1000 or more',
                    minValue(1000)
                ),
            },
        };
    },
    mixins: [ui, number],
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
        }),
        ...mapActions('transaction', {
            transactionDeposit: 'deposit',
            transactionGetBank: 'getBank',
        }),
        selectAmount(n) {
            if (n > 0) {
                this.amount += n;
            } else {
                this.amount = n;
            }
        },
        async submit() {
            this.v$.$touch();
            this.submitted = true;
            if (this.v$.$invalid) {
                return;
            } else {
                this.v$.$validate();
                const res = await this.transactionDeposit({
                    amount: this.amount,
                });

                if (res.success) {
                    this.closePopup('deposit-popup');
                    this.reset();
                    Swal.fire({
                        title: this.$t('Deposit request completed'),
                        type: 'success',
                        icon: 'success',
                        confirmButtonText: this.$t('ok'),
                    });
                } else {
                    this.closePopup('deposit-popup');
                    this.reset();
                    Swal.fire({
                        title: this.$t(res.message),
                        type: 'error',
                        icon: 'error',
                        confirmButtonText: this.$t('ok'),
                    });
                }
            }
        },
        async getBankDetails() {
            const res = await this.transactionGetBank();
            if (res) {
                this.bank_name = res.bank_name;
                this.account_holder = res.account_holder;
                this.account_number = res.account_number;
            }
        },
        reset() {
            this.v$.$reset();
            this.bank_name = null;
            this.account_holder = null;
            this.account_number = null;
            this.amount = 0;
            this.submitted = false;
        },
    },
    mounted() {
        var vm = this;
        let popupBg = document.getElementById('deposit-popup');
        if (popupBg) {
            popupBg.addEventListener('mousedown', function (e) {
                e = window.event || e;
                if (this === e.target) {
                    vm.reset();
                }
            });
        }
    },
};
</script>
