<template>
    <footer>
        <div class="footer-page">
            <img
                src="@/assets/img/footer.png"
                alt="" />
        </div>
    </footer>
    <div class="mob footer main">
        <div class="mob customercenter-container">
            <ul class="list-inline list-unstyled">
                <li
                    class="ngdialog-open"
                    @click="goToNotice">
                    <img
                        src="@/assets/img/notice-icon.svg"
                        alt=""
                        class="filter-color-yellow" />
                    <p>공지사항</p>
                </li>

                <li
                    class="ngdialog-open"
                    @click="goToBetHistory">
                    <img
                        src="@/assets/img/history-icon.svg"
                        alt=""
                        class="filter-color-yellow" />
                    <p>베팅이력</p>
                </li>
                <li
                    class="ngdialog-open"
                    :class="{'link-disabled' : this.profile.type=='offline'}"
                    @click="goToDeposit">
                    <img
                        src="@/assets/img/deposit-icon.svg"
                        alt=""
                        class="filter-color-yellow" />
                    <p>입금신청</p>
                </li>
                <li
                    class="ngdialog-open"
                    :class="{'link-disabled' : this.profile.type=='offline'}"
                    @click="goToWithdraw">
                    <img
                        src="@/assets/img/withdraw-icon.svg"
                        alt=""
                        class="filter-color-yellow" />
                    <p>출금신청</p>
                </li>
                <li
                    class="ngdialog-open"
                    @click="goToCoupon">
                    <img
                        src="@/assets/img/promo-icon.svg"
                        alt=""
                        class="filter-color-yellow" />
                    <p style="position: relative">
                        쿠폰
                        <span
                            class="notif-badge"
                            style="top: -40px; right: -7px"
                            v-if="profile.coupon.unclaimed"
                            >{{ profile.coupon.unclaimed }}</span
                        >
                    </p>
                </li>
                <li
                    class="ngdialog-open"
                    @click="goToTicket">
                    <img
                        src="@/assets/img/dm-icon.svg"
                        alt=""
                        class="filter-color-yellow" />
                    <p style="position: relative">
                        고객센터
                        <span
                            class="notif-badge"
                            style="top: -40px; right: -7px"
                            v-if="profile.ticket.unread"
                            >{{ profile.ticket.unread }}</span
                        >
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ui from '../mixins/ui';
import { mapGetters } from 'vuex';
export default {
    mixins: [ui],
    computed: {
        ...mapGetters('auth', ['authenticated', 'profile']),
    },
    methods: {
        goToTransaction() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('transaction-popup');
            }
        },
        goToDeposit() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                if(this.profile.type=='online') {
                    this.openPopup('deposit-popup');
                }
            }
        },
        goToWithdraw() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                if(this.profile.type=='online') {
                    this.openPopup('withdraw-popup');
                }
            }
        },
        goToTicket() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('ticket-popup');
            }
        },
        goToCoupon() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('coupon-popup');
            }
        },
        goToNotice() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('notice-popup');
            }
        },
        goToEvent() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('event-popup');
            }
        },
        goToProfile() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('my-page');
            }
        },
        goToBetHistory() {
            if (!this.authenticated) {
                this.openPopup('login');
            } else {
                this.openPopup('bet-history-popup');
            }
        },
    },
};
</script>

<style></style>
