<template>
    <div
        id="ticket-popup"
        class="popup-bg">
        <div class="popup">
            <div class="popup-header">
                <a
                    href="javascript:void(0);"
                    class="popup-close"
                    @click="
                        closePopup('ticket-popup');
                        initList(1);
                    ">
                </a>
            </div>
            <div class="popup-details">
                <div class="popup-content">
                    <div class="title">
                        INQUIRY
                        <div class="sub-title">문의</div>
                    </div>

                    <div class="uk-modal-body">
                        <div
                            class="content w-ba pt-5"
                            style="margin-top: 1em">
                            <div
                                style="
                                    overflow: hidden;
                                    position: relative;
                                    display: block;
                                ">
                                <div class="board-panel event">
                                    <table width="100%">
                                        <colgroup>
                                            <col width="40%" />
                                            <col width="10%" />
                                            <col width="50%" />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th class="title">내용</th>
                                                <th class="title">상태</th>
                                                <th class="title">날짜</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="r in data.list"
                                                :key="r._id"
                                                :class="{
                                                    'tr-unread':
                                                        !r.customer_read,
                                                }">
                                                <td
                                                    style="
                                                        text-align: center;
                                                        cursor: pointer;
                                                    "
                                                    @click="viewTicket(r._id)">
                                                    {{ r.subject }}
                                                </td>
                                                <td style="text-align: center">
                                                    <span
                                                        :class="
                                                            r.status === 'open'
                                                                ? 'text-open'
                                                                : 'text-closed'
                                                        ">
                                                        {{ $t(r.status) }}</span
                                                    >
                                                </td>
                                                <td style="text-align: center">
                                                    {{ r.created_at }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div
                                        class="form-group"
                                        style="
                                            margin-top: 20px;
                                            text-align: center;
                                        "
                                        v-if="data.list.length <= 0">
                                        <div
                                            class="form-footer dflex-ac-jc pagination text-base">
                                            <div>내용없음</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        :data="data"
                        @emitPage="initList"
                        class="w-full"
                        v-if="data.list.length" />
                </div>
                <div class="popup-footer">
                    <div class="action">
                        <button
                            type="button"
                            class="hover-link red"
                            @click="openPopup('create-ticket-popup')">
                            <span class="os-cont">문의하기</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CreateTicketPopup @emitPage="initList" />
    <ViewTicketPopup
        :data="ticket"
        @reloadView="viewTicket"
        @reloadList="initList"
        @clearDetails="clearDetails" />
</template>

<script>
import Layout from '@/components/Layout';
import Pagination from '@/components/Pagination';
import ui from '@/mixins/ui';
import datetime from '@/mixins/datetime';
import number from '@/mixins/number';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import CreateTicketPopup from '@/components/CreateTicketPopup.vue';
import ViewTicketPopup from '@/components/ViewTicketPopup.vue';

export default {
    name: 'Customer Service',
    components: {
        Layout,
        Pagination,

        CreateTicketPopup,
        ViewTicketPopup,
    },
    mixins: [ui, datetime, number],
    data() {
        return {
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            ticket: {
                _id: null,
                subject: null,
                content: null,
                category: null,
                role: null,
                handler_read: false,
                customer_read: false,
                status: null,
                created_at: null,
                comments: [],
                handler: null,
                customer: {
                    username: null,
                },
            },
            reply: {
                content: '',
            },
        };
    },
    computed: {
        ...mapGetters('auth', ['authenticated', 'profile']),
    },
    props: {
        trigger: {
            type: Number,
        },
    },
    watch: {
        'profile.role'(role) {
            if (role) {
                this.initList(1);
            }
        },
        trigger(n) {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
        }),
        ...mapActions('ticket', {
            ticketGetList: 'getList',
            ticketView: 'view',
            create: 'createTicket',
            commentCreate: 'commentCreate',
        }),

        emitPage(page) {
            this.$emit('emitPage', parseInt(page));
        },
        setActiveTab(tab) {
            this.active = tab;
        },
        goToCreateTicket() {
            this.openPopup('create-ticket-popup');
        },
        async initList(p) {
            var pl = {
                page_no: p ? p : 1,
                limit: 50,
            };
            const data = await this.ticketGetList(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
        },
        async submitComment(_id) {
            const res = await this.commentCreate({
                ticket_id: _id,
                form_data: this.reply,
            });
            if (res) {
                if (res.success) {
                    this.viewTicket(_id);
                    document.querySelector('.ql-editor').innerHTML = null;
                }
                Swal.fire({
                    title: res.success
                        ? this.$t('comment has been created')
                        : this.$t('creating comment failed'),
                    icon: res.success ? 'success' : 'error',
                    timer: 2000,
                });
            }
        },
        async viewTicket(id) {
            const data = await this.ticketView(id);
            this.openPopup('view-ticket-popup');

            this.authGetProfile();
            this.initList(this.data.current_page);
            this.ticket._id = data._id;
            this.ticket.subject = data.subject;
            this.ticket.content = data.content;
            this.ticket.category = data.category;
            this.ticket.comments = data.comments;
            this.ticket.status = data.status;
            this.ticket.customer.username = data.customer.username;
            this.ticket.created_at = data.created_at;
        },
        resetReply() {
            document.querySelector('.ql-editor').innerHTML = null;
        },
        clearDetails() {
            this.ticket._id = null;
            this.ticket.subject = null;
            this.ticket.content = null;
            this.ticket.category = null;
            this.ticket.comments = null;
            this.ticket.status = null;
            this.ticket.customer.username = null;
            this.ticket.created_at = null;
        },
    },
    mounted() {
        if (this.profile.role) {
            this.initList(1);
        }
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.bind('ticket', (e) => {
                this.initList(1);
                if (this.ticket._id) {
                    this.viewTicket(this.ticket._id);
                }
            });
        }
        var vm = this;
        let popupBg = document.getElementById('ticket-popup');
        if (popupBg) {
            popupBg.addEventListener('mousedown', function (e) {
                e = window.event || e;
                if (this === e.target) {
                    vm.initList(1);
                }
            });
        }
    },
    beforeUnmount() {
        if (Boolean(window.PusherChannel)) {
            window.PusherChannel.unbind('ticket');
        }
    },
};
</script>
