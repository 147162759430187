<template>
    <div
        id="my-page"
        class="popup-bg">
        <div class="popup">
            <div class="popup-header">
                <a
                    href="javascript:void(0);"
                    class="popup-close"
                    @click="closePopup('my-page')">
                </a>
            </div>
            <div class="popup-details">
                <div class="popup-content">
                    <div class="title">
                        MY PAGE
                        <div class="sub-title">마이페이지</div>
                    </div>

                    <div
                        class="uk-modal-body"
                        style="margin-top: 1em">
                        <div class="content w-ba pt-5">
                            <div
                                style="
                                    overflow: hidden;
                                    position: relative;
                                    display: block;
                                "
                                id="event_wrapper">
                                <div class="board-panel event">
                                    <table width="100%">
                                        <colgroup>
                                            <col width="50%" />
                                            <col width="50%" />
                                        </colgroup>

                                        <tbody>
                                            <tr>
                                                <td>아이디</td>
                                                <td>{{ profile.username }}</td>
                                            </tr>
                                            <tr>
                                                <td>잔액</td>
                                                <td>
                                                    {{
                                                        numberString(
                                                            profile.balance
                                                        )
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>포인트</td>
                                                <td>
                                                    <span>
                                                        {{
                                                            numberString(
                                                                profile.points
                                                            )
                                                        }}</span
                                                    >
                                                    <button
                                                        :disabled="
                                                            profile.points <= 0
                                                        "
                                                        type="button"
                                                        class="hover-link btn btn-blue"
                                                        style="
                                                            min-height: 20px;
                                                            margin-left: 1em;
                                                            font-size: 10px;
                                                            height: 20px;
                                                        "
                                                        @click="
                                                            convertPoints()
                                                        ">
                                                        포인트머니전환
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>마지막 로그인</td>
                                                <td>
                                                    {{ profile.last_login_at }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>최근 접속주소</td>
                                                <td>
                                                    {{ profile.last_login_ip }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ui from '@/mixins/ui';
import number from '@/mixins/number';
import Swal from 'sweetalert2';
export default {
    components: {},
    mixins: [ui, number],
    data() {
        return {};
    },
    computed: {
        ...mapGetters('auth', ['authenticated', 'profile']),
    },
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
            authConvertPoints: 'convertPoints',
        }),
        async convertPoints() {
            const confirmed = await Swal.fire({
                title: '포인트머니를 전환 하시겠습니까?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('ok'),
            }).then((result) => {
                return result.isConfirmed;
            });
            if (confirmed) {
                const res = await this.authConvertPoints();
                if (res.success) {
                    this.authGetProfile();
                }
                Swal.fire({
                    title: this.$t(res.data.message),
                    icon: res.data.status,
                    timer: res.success ? 2000 : null,
                    confirmButtonText: this.$t('ok'),
                });
            }
        },
    },
    mounted() {},
};
</script>
