<template>
    <Layout>
        <Popup v-if="authenticated" />
        <Main />
    </Layout>
</template>

<script>
// @ is an alias to /src
import Layout from '@/components/Layout.vue';
import Main from '@/components/Main.vue';
import Popup from '@/components/Popup.vue';
import { mapActions,mapGetters } from 'vuex';

export default {
    name: 'HomeView',
    components: {
        Layout,
        Main,
        Popup,
    },
    computed: {
        ...mapGetters('auth', ['authenticated','role'])
    },
};
</script>
