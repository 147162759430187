<template>
    <div
        id="view-ticket-popup"
        class="popup-bg">
        <div class="popup">
            <div class="popup-header">
                <a
                    href="javascript:void(0);"
                    @click="
                        closePopup('view-ticket-popup');
                        reset();
                        clear();
                    "
                    class="popup-close">
                </a>
            </div>
            <div
                class="popup-details"
                style="min-height: unset">
                <div class="popup-content">
                    <div class="title">
                        INQUIRY
                        <div class="sub-title">문의</div>
                    </div>

                    <div class="uk-modal-body">
                        <div
                            class="content w-ba pt-5"
                            style="margin-top: 1em; min-height: unset">
                            <div
                                style="
                                    overflow: hidden;
                                    position: relative;
                                    display: block;
                                    margin-bottom: 1em;
                                    padding: 1em;
                                "
                                id="event_wrapper">
                                <div class="board-panel event">
                                    <h3 style="margin: 0">
                                        주제: {{ data.subject }}
                                    </h3>
                                    <!-- <div style="font-size: 0.8rem">
                                                {{ data.user.name }} |
                                                {{ data.user.username }}
                                            </div> -->
                                    <div style="font-size: 0.8rem">
                                        {{ data.created_at }}
                                    </div>
                                    <div style="margin-top: 1em; color: #fff">
                                        <div v-html="data.content"></div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-for="c in data.comments"
                                :key="c._id"
                                style="
                                    margin-bottom: 1em;
                                    overflow: hidden;
                                    position: relative;
                                    display: block;
                                    border-top: 1px solid #4a4545;
                                    padding: 1em;
                                "
                                id="event_wrapper">
                                <div
                                    class="board-panel event"
                                    :class="{
                                        'text-end': c.role != 'player',
                                    }">
                                    <strong
                                        style="
                                            margin: 0;
                                            color: rgb(138 138 230);
                                        "
                                        v-if="c.role != 'player'">
                                        {{ $t('manager') }}
                                    </strong>
                                    <div style="font-size: 0.8rem">
                                        {{ c.created_at }}
                                    </div>
                                    <div style="margin-top: 1em; color: #fff">
                                        <div v-html="c.content"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form class="ui form equal width popup-form">
                        <div
                            class="field required"
                            style="margin-bottom: 0">
                            <textarea
                                style="min-height: 100px; margin-top: 1em"
                                :class="{
                                    'is-invalid':
                                        submitted && v$.pl.comment.$error,
                                }"
                                placeholder="내용 입력"
                                v-model="pl.comment"></textarea>
                            <div
                                v-for="(item, index) in v$.pl.comment.$errors"
                                :key="index"
                                class="invalid-feedback">
                                <small v-if="item.$message">
                                    {{ $t(item.$message) }}
                                </small>
                            </div>
                        </div>
                        <div
                            class="popup-footer"
                            style="padding: 0">
                            <div
                                class="action"
                                style="padding: 0">
                                <button
                                    type="button"
                                    class="hover-link red"
                                    @click="submitComment()"
                                    :disabled="loading">
                                    <span class="os-cont">댓글 보내기</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ui from '@/mixins/ui';
import { mapActions, mapGetters } from 'vuex';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Swal from 'sweetalert2';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    name: 'Ticket View',
    components: {},
    mixins: [ui],
    data() {
        return {
            loading: false,
            submitted: false,
            pl: {
                comment: null,
            },
        };
    },
    props: {
        data: {
            type: Object,
        },
    },
    validations() {
        return {
            pl: {
                comment: {
                    required: helpers.withMessage('required', required),
                },
            },
        };
    },
    // watch: {
    //     data: {
    //         handler() {
    //             var content = document.querySelector(
    //                 '#view-ticket-popup .content'
    //             );
    //             setTimeout(() => {
    //                 content.scroll({
    //                     top: content.scrollHeight,
    //                 });
    //             }, 500);
    //         },
    //         deep: true,
    //     },
    // },
    computed: {
        ...mapGetters('auth', ['authenticated', 'profile']),
    },
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
        }),
        ...mapActions('ticket', {
            commentCreate: 'commentCreate',
        }),
        async submitComment() {
            this.submitted = true;
            this.v$.pl.$touch();
            if (this.v$.pl.$invalid) {
                return;
            } else {
                var fd = new FormData();
                fd.append('content', this.pl.comment);
                this.loading = true;
                const res = await this.commentCreate({
                    ticket_id: this.data._id,
                    form_data: fd,
                });
                this.loading = false;
                if (res.success) {
                    this.$emit('reloadView', this.data._id);
                    this.reset();
                }
                Swal.fire({
                    title: this.$t(res.data.message),
                    icon: res.success ? 'success' : 'error',
                    timer: 2000,
                    confirmButtonText: this.$t('ok'),
                });
            }
        },
        reset() {
            this.pl.comment = null;
            this.v$.pl.$reset();
        },
        clear() {
            this.$emit('clearDetails');
        },
    },
    mounted() {
        var vm = this;
        let popupBg = document.getElementById('view-ticket-popup');
        if (popupBg) {
            popupBg.addEventListener('mousedown', function (e) {
                e = window.event || e;
                if (this === e.target) {
                    vm.reset();
                }
            });
        }
    },
};
</script>
