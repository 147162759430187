import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "../state/store"

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/vendors/:provider(casino||slot)',
		name: 'vendor-list',
		component: () => import('../views/vendor-list.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/game/:type(casino|slot)',
		name: 'game-list',
		component: () => import('../views/game-list.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: "/game-launch/:type(casino|slot)",
		name: "game-launch",
		component: () => import("../views/game-launch.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/:catchAll(.*)",
		redirect: "/",
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

router.beforeEach(async (routeTo, routeFrom, next) => {
	const isAuthenticated = store.getters["auth/authenticated"];
	if (isAuthenticated) {
		await store.dispatch("auth/getProfile");
	}
	if ((routeTo.path == '/vendors/casino' && store.getters["auth/profile"].menu.table != 'honorlink')
		|| (routeTo.path == '/vendors/slot' && store.getters["auth/profile"].menu.slots != 'honorlink')) {
		next("/");
		return;
	}
	if (routeTo.matched.some((record) => record.meta.requiresAuth)) {
		if (!isAuthenticated) {
			next("/");
		} else {
			next();
		}
	} else if (routeTo.matched.some((record) => record.meta.requiresVisitor)) {
		if (isAuthenticated) {
			next("/");
			console.log("authenticated");
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;

