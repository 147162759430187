<template>
    <div
        id="bet-history-popup"
        class="popup-bg">
        <div class="popup">
            <div class="popup-header">
                <a
                    href="javascript:void(0);"
                    class="popup-close"
                    @click="
                        closePopup('bet-history-popup');
                        initList(1);
                    ">
                </a>
            </div>
            <div class="popup-details">
                <div class="popup-content">
                    <div class="title">
                        BET HISTORY
                        <div class="sub-title">베팅이력</div>
                    </div>
                    <div class="popup-tabs">
                        <button
                            type="button"
                            :disabled="loading"
                            @click="game = 'table'"
                            :class="{ active: game == 'table' }">
                            {{ $t('casino') }}
                        </button>
                        <button
                            type="button"
                            :disabled="loading"
                            @click="game = 'slot'"
                            :class="{ active: game == 'slot' }">
                            {{ $t('slot') }}
                        </button>
                    </div>
                    <div class="uk-modal-body">
                        <div
                            class="content w-ba pt-5"
                            style="margin-top: 1em">
                            <img
                                v-if="loading"
                                class="page-loader"
                                src="@/assets/img/loader2.svg"
                                alt="" />
                            <div
                                style="
                                    overflow: hidden;
                                    position: relative;
                                    display: block;
                                ">
                                <div class="board-panel event">
                                    <table width="100%">
                                        <!-- <colgroup>
                                            <col width="15%" />
                                            <col width="35%" />
                                            <col width="50%" />
                                        </colgroup> -->
                                        <thead>
                                            <tr>
                                                <th class="title">게임</th>
                                                <th class="title text-end">
                                                    금액
                                                </th>

                                                <th class="title">상태</th>
                                                <th class="title text-end">
                                                    지급금액
                                                </th>
                                                <th class="title">생성일자</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="r in data.list"
                                                :key="r._id">
                                                <td style="text-align: center">
                                                    {{ r.game }}
                                                </td>
                                                <td
                                                    style="
                                                        text-align: end;
                                                        color: rgb(204 204 204);
                                                    ">
                                                    {{ numberString(r.amount) }}
                                                </td>

                                                <td style="text-align: center">
                                                    <span
                                                        :class="{
                                                            'text-warning':
                                                                r.status ==
                                                                'pending',
                                                            'text-info':
                                                                r.status ==
                                                                'win',
                                                            'text-danger':
                                                                r.status ==
                                                                'lose',
                                                        }">
                                                        {{ $t(r.status) }}
                                                    </span>
                                                </td>
                                                <td
                                                    style="
                                                        text-align: end;
                                                        color: rgb(
                                                            244,
                                                            106,
                                                            106
                                                        );
                                                    ">
                                                    {{
                                                        r.win_amount
                                                            ? numberString(
                                                                  r.win_amount
                                                              )
                                                            : ''
                                                    }}
                                                </td>
                                                <td style="text-align: center">
                                                    {{ r.created_at }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div
                                        class="form-group"
                                        style="
                                            margin-top: 20px;
                                            text-align: center;
                                        "
                                        v-if="
                                            data.list.length <= 0 && !loading
                                        ">
                                        <div
                                            class="form-footer dflex-ac-jc pagination text-base">
                                            <div>내용없음</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        :data="data"
                        @emitPage="initList"
                        class="w-full"
                        v-if="data.list.length" />
                </div>
                <!-- <div class="popup-footer">
                    <div class="action">
                        <button
                            type="button"
                            class="hover-link red"
                            @click="openPopup('create-bet-history-popup')">
                            <span class="os-cont">문의하기</span>
                        </button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import Layout from '@/components/Layout';
import Pagination from '@/components/Pagination';
import ui from '@/mixins/ui';
import datetime from '@/mixins/datetime';
import number from '@/mixins/number';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Customer Service',
    components: {
        Layout,
        Pagination,
    },
    mixins: [ui, datetime, number],
    data() {
        return {
            loading: false,
            game: 'table',
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
        };
    },
    computed: {
        ...mapGetters('auth', ['authenticated', 'profile']),
    },
    watch: {
        'profile.role'(role) {
            if (role) {
                this.initList(1);
            }
        },
        game() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
        }),
        ...mapActions('bet', {
            betGetList: 'getList',
        }),
        async initList(p) {
            var pl = {
                game: this.game,
                page_no: p ? p : 1,
                limit: 50,
            };
            this.data.list = [];
            this.data.links = [];
            this.loading = true;
            const data = await this.betGetList(pl);
            this.loading = false;
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
        },
    },
    mounted() {
        if (this.profile.role) {
            this.initList(1);
        }
        var vm = this;
        let popupBg = document.getElementById('bet-history-popup');
        if (popupBg) {
            popupBg.addEventListener('mousedown', function (e) {
                e = window.event || e;
                if (this === e.target) {
                    vm.initList(1);
                }
            });
        }
    },
};
</script>
