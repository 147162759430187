<template>
    <div
        id="withdraw-popup"
        class="popup-bg">
        <div class="popup">
            <div class="popup-header">
                <a
                    href="javascript:void(0);"
                    class="popup-close"
                    @click="
                        closePopup('withdraw-popup');
                        reset();
                    ">
                </a>
            </div>
            <div class="popup-body">
                <div class="popup-content">
                    <div class="title">
                        WITHDRAW
                        <div class="sub-title">출금 신청</div>
                    </div>

                    <div
                        action=""
                        class="popup-transaction-form">
                        <div>
                            <label
                                for=""
                                style="display: block"
                                >금액</label
                            >
                            <VueNumberFormat
                                id="amount"
                                class="number"
                                :class="{
                                    'is-invalid': submitted && v$.amount.$error,
                                }"
                                v-model:value="amount"
                                :options="{
                                    precision: 0,
                                    prefix: '',
                                    suffix: '',
                                    decimal: '.',
                                    thousand: ',',
                                    acceptNegative: false,
                                    isInteger: true,
                                }"></VueNumberFormat>
                            <div
                                v-for="(item, index) in v$.amount.$errors"
                                :key="index"
                                class="invalid-feedback">
                                <small v-if="item.$message">
                                    {{ $t(item.$message) }}
                                </small>
                            </div>
                        </div>
                        <div v-if="profile.withdraw.pin">
                            <label
                                for=""
                                style="display: block"
                                >충·환전 비밀번호</label
                            >
                            <input
                                type="password"
                                maxlength="4"
                                onkeypress=" return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 13;"
                                v-model="pin"
                                class="number"
                                :class="{
                                    'is-invalid': submitted && v$.pin.$error,
                                }" />
                            <div
                                v-for="(item, index) in v$.pin.$errors"
                                :key="index"
                                class="invalid-feedback">
                                <small v-if="item.$message">
                                    {{ $t(item.$message) }}
                                </small>
                            </div>
                        </div>
                        <div class="preset-amount">
                            <button
                                v-for="n in preset_amounts"
                                :key="n"
                                type="button"
                                @click="selectAmount(n)"
                                class="hover-link btn"
                                :class="{ 'btn-red': n == 0 }">
                                {{ n == 0 ? $t('clear') : numberString(n) }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="popup-footer">
                    <button
                        class="btn-yellow"
                        style="width: 100%; cursor: pointer"
                        @click="submit()">
                        환전요청
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ui from '@/mixins/ui';
import number from '@/mixins/number';
import {
    required,
    minValue,
    minLength,
    requiredIf,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions, mapGetters } from 'vuex';
import Swal from 'sweetalert2';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            submitted: false,
            preset_amounts: [10000, 50000, 100000, 500000, 1000000, 0],
            bank_name: null,
            account_holder: null,
            account_number: null,
            amount: 0,
            pin: null,
            password: null,
        };
    },
    validations() {
        return {
            amount: {
                required: helpers.withMessage('required', required),
                minValueValue: helpers.withMessage(
                    'the minimum withdrawal application amount is 1000 or more',
                    minValue(1000)
                ),
            },
            pin: {
                requiredIf: helpers.withMessage(
                    'please enter your PIN number',
                    requiredIf(function () {
                        return this.profile.withdraw.pin;
                    })
                ),
                minLength: helpers.withMessage(
                    'must consist of 4 characters',
                    minLength(4)
                ),
            },
        };
    },
    computed: {
        ...mapGetters('auth', ['profile']),
    },
    mixins: [ui, number],
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
        }),
        ...mapActions('transaction', {
            transactionWithdraw: 'withdraw',
        }),
        selectAmount(n) {
            if (n > 0) {
                this.amount += n;
            } else {
                this.amount = n;
            }
        },
        async submit() {
            this.v$.$touch();
            this.submitted = true;
            if (this.v$.$invalid) {
                return;
            } else {
                // this.v$.$validate();
                const res = await this.transactionWithdraw({
                    amount: this.amount,
                    pin: this.pin,
                    // password: this.password,
                });
                if (res.success) {
                    this.authGetProfile();
                    this.closePopup('withdraw-popup');
                    this.reset();
                    Swal.fire({
                        title: this.$t('Exchange application completed'),
                        type: 'success',
                        icon: 'success',
                        confirmButtonText: this.$t('ok'),
                    });
                } else {
                    Swal.fire({
                        title: this.$t(res.message),
                        type: 'error',
                        icon: 'error',
                        confirmButtonText: this.$t('ok'),
                    });
                }
            }
        },
        async getBank() {
            const res = await this.transactionGetBank();
            if (res) {
                this.bank_name = res.bank_name;
                this.account_holder = res.account_holder;
                this.account_number = res.account_number;
            }
        },
        close() {
            this.closeModal('withdrawPopUp');
            this.reset();
        },
        reset() {
            this.v$.$reset();
            this.bank_name = null;
            this.account_holder = null;
            this.account_number = null;
            this.amount = 0;
            this.pin = null;
            this.submitted = false;
        },
    },
    mounted() {
        var vm = this;
        let popupBg = document.getElementById('withdraw-popup');
        if (popupBg) {
            popupBg.addEventListener('mousedown', function (e) {
                e = window.event || e;
                if (this === e.target) {
                    vm.reset();
                }
            });
        }
    },
};
</script>
