<template>
    <div
        id="view-event-popup"
        class="popup-bg"
        :class="{ 'bg-active': data._id != null }"
        style="background: rgba(0, 0, 0, 0.55)">
        <div class="popup">
            <div class="popup-header">
                <a
                    href="javascript:void(0);"
                    @click="
                        closePopup('view-event-popup');
                        reset();
                    "
                    class="popup-close">
                </a>
            </div>
            <div class="popup-details">
                <div class="popup-content">
                    <div class="title">
                        Event
                        <div class="sub-title">이벤트 보기</div>
                    </div>

                    <div class="uk-modal-body">
                        <div
                            class="content w-ba pt-5"
                            style="margin-top: 1em">
                            <div
                                style="
                                    overflow: hidden;
                                    position: relative;
                                    display: block;
                                "
                                id="event_wrapper">
                                <div class="board-panel event">
                                    <table width="100%">
                                        <tbody>
                                            <h3>
                                                {{ data.subject }}
                                            </h3>
                                            <div style="font-size: 0.8rem">
                                                {{ data.user.name }} |
                                                {{ data.user.username }}
                                            </div>
                                            <div style="font-size: 0.8rem">
                                                {{ data.created_at }}
                                            </div>
                                            <div style="margin-top: 2em">
                                                <div
                                                    v-html="data.content"></div>
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style="
                            display: flex;
                            align-items: center;
                            gap: 0.3em;
                            margin-top: 1em;
                        ">
                        <input
                            class="popup-checkbox"
                            type="checkbox"
                            id="hidePopup"
                            v-model="hidePopup" />
                        <label for="hidePopup">
                            {{ $t(`don't show for 24 hours`) }}</label
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ui from '@/mixins/ui';
import { mapActions, mapGetters } from 'vuex';
import $cookies from 'vue-cookies';
export default {
    name: 'Event View',
    components: {},
    mixins: [ui],
    data() {
        return {
            hidePopup: $cookies.get('hideEventPopup') ? true : false,
        };
    },
    props: {
        data: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters('auth', ['authenticated', 'profile']),
    },
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile',
        }),
        reset() {
            this.$emit('closeEventPopup');
            this.setHidden();
        },
        setHidden() {
            if (this.hidePopup) {
                $cookies.set('hideEventPopup', true, '1d');
            } else {
                $cookies.remove('hideEventPopup');
            }
        },
    },
    mounted() {},
};
</script>
