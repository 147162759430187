import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./state/store";

import "../src/assets/css/fonts.css"
import "../src/assets/css/hover-link.css"
import "../src/assets/css/swal2.css"
import "../src/assets/css/main.css"
import "../src/assets/css/popup.css"
import "../src/assets/css/responsive.css"

import { i18n } from "./i18n.js"
import VueNumberFormat from 'vue-number-format'
import 'odometer/themes/odometer-theme-default.css'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';


const globalOptions = {
    language: 'ko',
    theme: 'snow',
    modules: {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
            [{ color: [] }],
            [{ background: [] }],
            ['blockquote', 'code-block'],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'],
            [{ font: [] }],
            [{ align: [] }],
            ['clean']
        ]
    },

}
QuillEditor.props.globalOptions.default = () => globalOptions


createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueNumberFormat)
    .component('QuillEditor', QuillEditor)
    .mount('#app')


import $cookies from "vue-cookies";

// if (!window.opener) {
//     window.addEventListener("load", function () {
//         var dateString = $cookies.get("unload_time")
//             ? $cookies.get("unload_time")
//             : null;

//         if (dateString) {
//             const parts = dateString.match(/(\d+)/g);
//             const year = parseInt(parts[0]);
//             const month = parseInt(parts[1]) - 1;
//             const day = parseInt(parts[2]);
//             const hour = parseInt(parts[3]);
//             const minute = parseInt(parts[4]);
//             const second = parseInt(parts[5]);
//             const millisecond = parseInt(parts[6]);

//             const unload_time = new Date(
//                 Date.UTC(year, month, day, hour, minute, second, millisecond)
//             );
//             const current_time = new Date();

//             if (unload_time) {
//                 const timeDifference = Math.abs(
//                     current_time.getTime() - unload_time.getTime()
//                 );
//                 const seconds = Math.floor((timeDifference % 60000) / 1000);
//                 if (seconds > 4) {
//                     store.dispatch('auth/logoutUser')
//                     //    store.commit("auth/destroyAuth");
//                 }
//             }
//         }
//     });

//     window.addEventListener("beforeunload", function () {
//         $cookies.set("unload_time", new Date());
//     });
// }



document.addEventListener('contextmenu', event => event.preventDefault());
