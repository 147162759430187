import $api from "../api";
import axios from "axios";


export const actions = {
  getList({ commit, rootGetters, dispatch }, filename) {
    return new Promise(function (resolve) {
      axios
        .get(`/data/${filename}.json`, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            // commit("setData", res);
            resolve(res);
          }
        })
        .catch(function (err) {
          console.log(err, "err");
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => { });
          }
        });
    });
  },
  getCasinoGameList({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      axios
        .get(`/data/updated-casino-gamelist/${pl.game_id}.json`, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            resolve(res);
          }
        })
        .catch(function (err) {
          console.log(err, "err");
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => { });
          }
        });
    });
  },
  getGameLaunch({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      commit('triggerLaunching', true, { root: true })
      $api
        .get(`${rootGetters['auth/profile'].role}/game-launch?game_id=${pl.game_id}&vendor=${pl.vendor}`, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            // console.log("gamelaunch", res);
            // commit("setGameLaunch", res.data.data);
            resolve(res);

            commit('triggerLaunching', false, { root: true })
          }
        })
        .catch(function (err) {
          // console.log(err, "err");
          if (err.response.status == 401) {
            dispatch("auth/logout", {}, { root: true }).then(() => { });
          }
          else {
            resolve(err.response);
          }
          commit('triggerLaunching', false, { root: true })
        });

    });
  },
  getGameLaunch2({ commit, rootGetters, dispatch }, pl) {
    return new Promise(function (resolve) {
      commit('triggerLaunching', true, { root: true })
      $api
        .post(`${rootGetters['auth/profile'].role}/kplay/auth`, pl, {
          headers: {
            Authorization: rootGetters["auth/bearer_token"],
          },
        })
        .then(function (res) {
          if (res.status == 200) {
            resolve(res);
            commit('triggerLaunching', false, { root: true })
          }
        })
        .catch(function (err) {
          // // console.log(err, "err");
          // if (err.response.status == 401) {
          //   resolve(err.response);
          //   //   dispatch("auth/logout", {}, { root: true }).then(() => {});

          // }
          // if (err.response.status == 422) {
          resolve(err.response);
          // }
          commit('triggerLaunching', false, { root: true })
        });
    });
  },
}
